import * as Sentry from '@sentry/gatsby'

const ignoreFilter = ['obApi', 'NODE_TLS_REJECT_UNAUTHORIZED'].map((error) =>
  error.toLowerCase()
)

Sentry.init({
  dsn: 'https://db05eefd59124149b1c88454fb9328cc@o184059.ingest.sentry.io/1276805',
  enabled:
    process.env.NODE_ENV === 'production' &&
    process.env.SITE_NAME === 'recoverycom',
  environment: process.env.NODE_ENV,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.5,
  ignoreTransactions: ignoreFilter,
  integrations: [Sentry.captureConsoleIntegration({ levels: ['error'] })],
  beforeSend(event, hint) {
    if (
      hint?.originalException?.message &&
      ignoreFilter.some((error) =>
        hint.originalException.message.toLowerCase().includes(error)
      )
    ) {
      return null
    }

    return event
  }
})
